/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import SubFooter from "./subFooter"
import "../assets/stylesheets/layout.scss"
import styled, {ThemeProvider} from 'styled-components'
import theme from '../assets/stylesheets/theme'
import Banner from './banner'
import {SiteConfig} from '../data/siteConfig'

let Base = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  main {
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
  }
`

const Layout = ({ children }) => {
  let {title} = SiteConfig()
  return (
    <ThemeProvider theme={theme}>
      <Base>
        <Banner/>
        <Header siteTitle={title}/>
        <main>{children}</main>
        <Footer/>
        <SubFooter title={title}/>
      </Base>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
